/*
 * @Author: wyq
 * @Date: 2021-07-23 10:11:24
 * @LastEditTime: 2021-09-23 15:17:53
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \cat_student\src\components\index.js
 */
import Vue from 'vue'
import { deCentralization } from '../utils/util'

let el = deCentralization(require.context('./el', false, /\w+.vue$/), /index/, true);
let publicComponents = deCentralization(require.context('./public', false, /\w+.vue$/), /index/, true);
for (let item of el) {
  Vue.component(item.name, item)
}
for (let item of publicComponents) {
  Vue.component(item.name, item)
}