/*
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2022-04-15 17:30:31
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";

const routes = [
	{
		path: '/',
		redirect: '/adaptiveTesting'
	}, {
		path: "/login",
		name: "Login",
		component: () => import('../views/login'),
	}, {
		path: "/index",
		name: "Index",
		redirect: '/adaptiveTesting',
		component: () => import('../views/index'),
		children: [
			{
				path: "/adaptiveTesting",
				name: "AdaptiveTesting",
				component: () => import('../views/adaptiveTesting/index'),
			},
			{
				path: "/adaptiveTesting/detail",
				name: "AdaptiveTestingDetail",
				component: () => import('../views/adaptiveTesting/detail'),
			},
			{
				path: "/academicArchives",
				name: "AcademicArchives",
				component: () => import('../views/academicArchives/index'),
			},
			{
				path: "/wrongQuestion",
				name: "WrongQuestion",
				component: () => import('../views/wrongQuestion/index'),
			},
			{
				path: "/wrongQuestion/revised",
				name: "WrongQuestionRevised",
				component: () => import('../views/wrongQuestion/revised'),
			},
			{
				path: "/trainingCenter",
				name: "TrainingCenter",
				component: () => import('../views/trainingCenter/index'),
			}, {
				path: "/trainingCenter/record",
				name: "TrainingRecord",
				component: () => import('../views/trainingCenter/record')
			}, {
				path: "/teacherStudio",
				name: "TeacherStudio",
				component: () => import('../views/teacherStudio/index')
			}, {
				path: "/teacherStudio/trainingSet",
				name: "TrainingSet",
				component: () => import('../views/teacherStudio/trainingSet')
			}, {
				path: "/teacherStudio/statistics",
				name: "TrainingStatistics",
				component: () => import('../views/teacherStudio/statistics')
			}
		]
	}, {
		path: "/recordList",
		name: "RecordList",
		component: () => import('../views/academicArchives/recordList')
	}, {
		path: "/setting",
		name: "Setting",
		component: () => import('../views/setting/setting')
	}, {
		path: "/testingCenter/startCatTesting",
		name: "StartCatTesting",
		component: () => import('../views/testingCenter/startCatTesting'),
	}, {
		path: "/testingCenter/startErrorTesting",
		name: "StartErrorTesting",
		component: () => import('../views/testingCenter/startErrorTesting'),
	}, {
		path: "/testingCenter/errorReport",
		name: "ErrorReport",
		component: () => import('../views/testingCenter/errorReport')
	}, {
		path: "/testingCenter/catReport",
		name: "CatReport",
		component: () => import('../views/testingCenter/catReport')
	}, {
		path: "/testingCenter/previewTesting",
		name: "PreviewTesting",
		component: () => import('../views/testingCenter/previewTesting')
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, saveTop) {
		if (saveTop) {
			return saveTop;
		} else {
			return { x: 0, y: 0 }
		}
	},
});

router.beforeEach((to, from, next) => {
	// 路由校验权限
	// console.log(to.name, from.name)
	if (to.name != 'Login') {
		if (localStorage.getItem('userName')) {
			if ((to.name == 'RecordList') && (from.name == 'CatReport')
				|| (to.name == 'TrainingRecord') && (from.name == 'PreviewTesting')
				|| (to.name == 'TrainingCenter') && (from.name == 'PreviewTesting')
				// || (to.name == 'AdaptiveTesting') && (from.name == 'AdaptiveTestingDetail')
			) {
				localStorage.setItem('status', 'true')
			} else {
				localStorage.setItem('status', 'false')
			}
			next()
		} else {
			next({
				path: '/login'
			})
		}
	} else {
		next()
	}
})

Vue.use(VueRouter);

export default router;
