<!--
 * @Author: wyq
 * @Date: 2021-07-23 10:39:34
 * @LastEditTime: 2021-09-23 16:43:12
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\el\main.vue
-->
<template>
  <main class="el-main">
    <slot></slot>
  </main>
</template>

<script>
export default {
  name: 'ElMain',
  componentName: 'ElMain',
}
</script>
<style lang="scss" scoped>
.el-main {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  overflow: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px 32px 12px 20px;
}
</style>