<template>
  <div ref="katex" v-show="show" class="latex">{{value}}</div>
</template>
<script>
export default {
  name: 'latex',
  props: {
    value: String,
  },
  data() {
    return {
      show: true,
    }
  },
  watch: {
    value: {
      handler(val) {
        // this.show = false
        if (val) {
          setTimeout(() => {
            this.$formula(this.$refs['katex'])
            setTimeout(() => {
              this.show = true
            }, 100)
          }, 100)
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.latex {
  display: inline-block;
  min-height: 100%;
}
</style>
