<!--
 * @Author: wyq
 * @Date: 2021-09-30 11:08:15
 * @LastEditTime: 2021-09-30 14:48:28
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\public\message.vue
-->
<template>
  <div class="message">
    <template v-for="(item, index) in $store.state.msgPool">
      <div class="d-flex justify-center" transition="slide-y-transition" :key="index">
        <template v-if="item.light == false" transition="scale-transition">
          <v-alert
            dense
            :type="item.type"
            border="left"
            colored-border
            elevation="2"
          >{{ item.message }}</v-alert>
        </template>
        <template v-if="item.light == true" transition="scale-transition">
          <v-alert
            dense
            :type="item.type"
            outlined
            border="left"
            colored-border
            elevation="2"
          >{{item.message}}</v-alert>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'message',
}
</script>
<style>
.message {
  left: 50%;
  padding-top: 20px;
  z-index: 9999;
  display: flex;
  position: fixed;
  min-width: 300px;
  max-width: 460px;
  flex-direction: column;
  transform: translateX(-50%);
}
</style>