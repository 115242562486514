/*
 * @Author: wyq
 * @Date: 2021-07-23 10:11:04
 * @LastEditTime: 2021-10-08 17:21:01
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \cat_student\src\utils\util.js
 */
export const deCentralization = (r, exinclude = /index/, isMatchDefault = false) => {
  const paths = r.keys().filter(p => {
    return !exinclude.test(p)
  })
  const maps = paths.map(k => {
    if (isMatchDefault) {
      return r(k).default
    } else {
      return r(k)
    }
  })
  return maps
}

export function formattedData(res) {
  let form = {}
  const constantLabel = [
    'questionId',
    'type',
    'title',
    'optionNum',
    'answer',
    'answerProcess',
    'answerAnalysis',
    'answerAnalysis',
    'difficultyLevel',
    'ansSecond',
    'checkType',
    'checkContent',
    'discrimination',
    'guessing'
  ]

  for (const i of constantLabel) {
    form[i] = res[i]
  }
  const imgLabel = ['title', 'answerProcess', 'answerAnalysis']
  for (const i of imgLabel) {
    // console.log(`${i}Photo`, res[`${i}Photo`])
    form[`${i}Images`] = res[`${i}Photo`]
      ? res[`${i}Photo`].map((i) => {
        return {
          label: i.name,
          url: i.url,
        }
      })
      : []
  }
  if (res.type == 1||res.type == 4) {
    form.options = []
    for (let i = 0; i < res.optionNum; i++) {
      form.options.push({
        value: res[`option${i + 1}`],
        imageUrl: res[`option${i + 1}Photo`]
          ? res[`option${i + 1}Photo`].map((i) => {
            return {
              label: i.name,
              url: i.url,
            }
          })
          : [],
      })
    }
  }
	if (res.type == 4) {
		form.arrAnswer = res.answer ? res.answer.split(',') : []
		form.title = '【多选】' + form.title
	}
	return form
}

export function getTag(arr) {
  let result = []
  if (arr) {
    let tagList = this.$store.state.tagList
    arr.map((i) => {
      result.push(tagList.find((tag) => tag.value == i))
    })
  }
  return result
}