/*
 * @Author: wyq
 * @Date: 2021-09-30 11:19:47
 * @LastEditTime: 2021-09-30 11:23:04
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \cat_student\src\utils\message.js
 */
import store from '@/store/index'
export default {
  //成功提示
  success(data) {
    store.commit('message', {
      type: 'success', //message类型
      message: data.message ? data.message : data, //message内容
      time: data.time ? data.time : 3000, //message时长，无设置则默认3s
      light: data.light ? data.light : false, //message主题，无设置则默认dark
    })
  },

  //错误提示
  error(data) {
    store.commit('message', {
      type: 'error',
      message: data.message ? data.message : data,
      time: data.time ? data.time : 3000,
      light: data.light ? data.light : false,
    })
  },

  //消息提示
  info(data) {
    store.commit('message', {
      type: 'info',
      message: data.message ? data.message : data,
      time: data.time ? data.time : 3000,
      light: data.light ? data.light : false,
    })
  },

  //警告提示
  warning(data) {
    store.commit('message', {
      type: 'warning',
      message: data.message ? data.message : data,
      time: data.time ? data.time : 3000,
      light: data.light ? data.light : false,
    })
  },
}