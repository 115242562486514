/*
 * @Author: wyq
 * @Date: 2021-09-22 10:41:34
 * @LastEditTime: 2021-10-07 18:43:23
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\plugins\vuetify.js
 */
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0148FF',
        secondary: '#0DD1FF',
        accent: '#FFE900',
        error: '#FF3838',
        info: '#2196F3',
        success: '#52C41A',
        warning: '#FFB13F',
      },
    },
  },
  icons: {
    iconfont: 'mdi',
    values: {
      'round-add': 'icon-round-add',
      'round-reduce': 'icon-round-reduce',
      password: 'icon-password',
      user: 'icon-user',
      thumbtack: 'icon-thumbtack',
      timer: 'icon-timer',
      clock: 'icon-clock',
      success: 'icon-success',
      error: 'icon-error',
      'arrow-left': 'icon-arrow-left',
      'arrow-right': 'icon-arrow-right',
      'arrow-up': 'icon-arrow-up',
      'arrow-down': 'icon-arrow-down',
      back: 'icon-back',
      enter: 'icon-enter',
      settings: 'icon-settings',
      xqda: 'icon-xqda',
      ctzx: 'icon-ctzx',
      xlzx: 'icon-xlzx',
      zsy: 'icon-zsy',
      revise: 'icon-revise',
      kaiguankai: 'icon-kaiguanka',
      kaiguanguan: 'icon-kaiguanguan',
      zhuyi: 'icon-zhuyi',



    },
  },
});
