/*
 * @Author: wyq
 * @Date: 2021-07-06 16:56:12
 * @LastEditTime: 2021-10-22 16:51:17
 * @LastEditors: wyq
 * @Description:
 * @FilePath: \cat_student\src\utils\filters.js
 */
import moment from 'moment'

function datefmt(input, fmtstring) {
  if (input) {
    return moment(input * 1000).format(fmtstring);
  } else {
    return "";
  }
}

function formatSeconds(value) {
  var theTime = parseInt(value);// 秒
  var theTime1 = 0;// 分
  var theTime2 = 0;// 小时
  if (theTime >= 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 >= 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }

  var result = "" + parseInt(theTime);//秒
  if (10 > theTime > 0) {
    result = "0" + parseInt(theTime);//秒
  } else {
    result = "" + parseInt(theTime);//秒
  }

  if (10 > theTime1 > 0) {
    result = "0" + parseInt(theTime1) + ":" + result;//分，不足两位数，首位补充0，
  } else {
    result = "" + parseInt(theTime1) + ":" + result;//分
  }
  if (10 > theTime2 > 0) {
    result = "0" + parseInt(theTime2) + ":" + result;//分，不足两位数，首位补充0，
  } else {
    result = "" + parseInt(theTime2) + ":" + result;//分
  }
  return result;
}
export {
  datefmt,
  formatSeconds
}