<!--
 * @Author: wyq
 * @Date: 2021-09-22 09:25:04
 * @LastEditTime: 2021-12-21 13:01:41
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\App.vue
-->
<template>
  <v-app>
    <router-view />
    <message></message>
  </v-app>
</template>

<script>
export default {
  name: 'App',
}
</script>
<style lang="scss" scoped>
@import url('//at.alicdn.com/t/font_2827784_ybplz8p3y1l.css');

@font-face {
  font-family: PingFangSC-Bold;
  src: url('./assets/font/PingFangSC-Bold.otf');
}
@font-face {
  font-family: PingFangSC-Medium;
  src: url('./assets/font/PingFangSC-Medium.otf');
}
@font-face {
  font-family: PingFangSC-Regular;
  src: url('./assets/font/PingFangSC-Regular.otf');
}
@font-face {
  font-family: PingFangSC-Normal;
  src: url('./assets/font/PingFangSC-Normal.otf');
}
@font-face {
  //数字字体
  font-family: Akrobat-Black;
  src: url('./assets/font/Akrobat-Black.otf');
}
@font-face {
  //数字字体
  font-family: Akrobat-Semibold;
  src: url('./assets/font/Akrobat-Semibold.ttf');
}
.theme--light.v-application {
  height: 100vh;
  background-color: #f2f6fc;
  overflow: hidden;
  font-family: PingFangSC-Normal;
  .round-bg {
    &::before {
      position: absolute;
      left: -8vw;
      bottom: -16vw;
      content: '';
      width: 42vw;
      height: 42vw;
      border-radius: 50%;
      border: 26px solid #0148ff;
      opacity: 0.1;
    }
  }
}
</style>
<style lang="scss">
.v-card {
  box-shadow: 2px 6px 12px 0px rgba(0, 0, 0, 0.08) !important;
}
.v-btn {
  font-family: PingFangSC-Regular;
}
.v-application .katex .accent {
  background-color: transparent !important;
  border: none !important;
}
</style>
