<!--
 * @Author: wyq
 * @Date: 2021-07-23 09:59:59
 * @LastEditTime: 2021-09-23 15:19:26
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\el\aside.vue
-->
<template>
  <aside class="el-aside">
    <slot></slot>
  </aside>
</template>

<script>
export default {
  name: 'ElAside',
  componentName: 'ElAside',
}
</script>
<style lang="scss" scoped>
.el-aside {
  overflow: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;

  height: 100%;
}
</style>