<!--
 * @Author: wyq
 * @Date: 2021-07-23 10:00:32
 * @LastEditTime: 2021-07-23 10:42:58
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \knowledge_building_system\src\components\el\header.vue
-->
<template>
  <header class="el-header" :style="{ height }">
    <slot></slot>
  </header>
</template>

<script>
export default {
  name: 'ElHeader',

  componentName: 'ElHeader',

  props: {
    height: {
      type: String,
      // default: '60px',
    },
  },
}
</script>
<style lang="scss" scoped>
.el-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
</style>