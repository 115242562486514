/*
 * @Author: wyq
 * @Date: 2021-05-17 14:57:08
 * @LastEditTime: 2021-12-16 10:47:48
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\http\axios.js
 */
import axios from 'axios';
import Message from '@/utils/message'
import Router from '../router/index'

// axios.defaults.baseURL = 'http://39.101.176.138:80'
axios.defaults.timeout = 5000;

// 请求拦截
axios.interceptors.request.use(config => {
  config.headers.Authorization = localStorage.getItem('token')

  return config
}, error => {
  return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(response => {
  let data = response.data;
  if (data.Code == 200) {
    return Promise.resolve(data.Data)
  } else if (data.Code == 1002) {
    Router.go(-1)
    Message.warning({
      message: data.Msg,
    });
  }
  else {
    Message.warning({
      message: data.Msg,
    });
  }
  return Promise.reject(data)
}, error => {
  // 请求失败
  if (error && error.response) {
    switch (error.response.status) {
      case 401:
        Router.replace({
          path: '/login'
        })
        break
      default:
        // 如果以上都不是的处理
        return Promise.reject(error);
    }
  }

})
export default axios