<!--
 * @Author: wyq
 * @Date: 2021-09-23 14:26:42
 * @LastEditTime: 2021-11-02 15:16:26
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\public\colorTitle.vue
-->
<template>
  <div ref="katex" class="color-title">
    <div class="square" :style="{ backgroundColor: backgroundColor }"></div>
    <slot></slot>
    {{ title }}
  </div>
</template>
<script>
export default {
  name: 'colorTitle',
  props: {
    color: String,
    title: String,
  },
  watch: {
    title: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.$formula(this.$refs['katex'])
          }, 100)
        }
      },
      immediate: true,
    },
  },
  computed: {
    backgroundColor() {
      let map = {
        primary: '#0148FF',
        secondary: '#0DD1FF',
        accent: '#FFE900',
        error: '#FF6262',
        info: '#2196F3',
        success: '#00CE92',
        warning: '#FFB13F',
      }
      return this.color ? map[this.color] ? map[this.color] : this.color : '#0148FF'
    },
  },
  mounted() { },
}
</script>
<style lang="scss" scoped>
.color-title {
  position: relative;
  font-size: 20px;
  line-height: 26px;
  font-family: PingFangSC-Bold;
  color: #333;

  .square {
    display: inline-block;
    margin-right: 16px;
    width: 26px;
    height: 26px;
    border-radius: 8px;
    vertical-align: top;
  }
}
</style>