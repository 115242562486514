/*
 * @Author: wyq
 * @Date: 2021-06-08 11:18:12
 * @LastEditTime: 2021-10-07 19:27:25
 * @LastEditors: yy
 * @Description: 
 * @FilePath: \cat_student\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'

import service from './http/service'

import Vuetify from 'vuetify'
import vuetify from './plugins/vuetify'
import '@mdi/font/css/materialdesignicons.css'
import './components/index'
import store from './store/index'

import Message from './utils/message'

import 'katex/dist/katex.css'
import katex from './utils/katex'

import MathKeyboard from './components/mathKeyboard/index.js'
import moment from 'moment'
import { formattedData,getTag } from './utils/util'
import * as filters from './utils/filters.js'
import * as echarts from "echarts";


Vue.prototype.$formula = katex
Vue.use(MathKeyboard)
Vue.prototype.$message = Message

Vue.config.productionTip = false
Vue.prototype.$service = service;
Vue.use(Vuetify);
Vue.prototype.$moment = moment
Vue.prototype.$formattedData = formattedData
Vue.prototype.$getTag = getTag
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.echarts = echarts


new Vue({
  router,
  render: h => h(App),
  vuetify,
  store
}).$mount('#app')
