/*
 * @Author: wyq
 * @Date: 2021-05-18 10:17:34
 * @LastEditTime: 2021-10-12 11:20:16
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\http\service.js
 */
import http from './axios';

const baseUrl = '/v1'
// 登录
function login(params) {
  return http.post(`${baseUrl}/user/login`, params)
}
// 注册
function register(params) {
  return http.post(`${baseUrl}/user/register`, params)
}
// 获取行业树
function getIndustryTree(params) {
  return http.get(`${baseUrl}/company/get_industry_type_tree`, { params })
}
// 获取知识点分类树
function getTree(params) {
  return http.post(`${baseUrl}/catKG/tree`, params)
}
// 获取用户信息
function getUserInfo(params) {
  return http.post(`${baseUrl}/user/getUserInfo`, { params })
}
//CAT——自适应测试列表
function getTopicList(params) {
  return http.post(`${baseUrl}/cat/getTopicList`, params)
}

// 获取训练中心列表 
function getTrainingCenterList(params) {
  return http.post(`${baseUrl}/trainCenter/searchTrainReq`, params)
}
//错题中心——错题列表
function getErrorQuestionList(params) {
  return http.post(`${baseUrl}/errorQuestion/searchErrorQuestion`, params)
}
//学情档案——磁盘图
function getTreeMap(params) {
  return http.post(`${baseUrl}/userProfile/getAbilityMapReq`, params)
}
//学情档案——专题名称列表(仅用在学情档案)
function getTopicTitleList(params) {
  return http.post(`${baseUrl}/cat/getTopicTitleList`, params)
}
//cat/学情档案——专题记录linemap 
function getTopicArchive(params) {
  return http.post(`${baseUrl}/cat/getTopicArchive`, params)
}
//学情档案——自适应测试记录
function getUserCatRecord(params) {
  return http.post(`${baseUrl}/userProfile/getUserCatRecord`, params)
}
//学情档案——自适应测试记录报告(未接)
function getReport(params) {
  return http.post(`${baseUrl}/userProfile/getReport`, params)
}

// 自适应测试-开始CAT测试
function startCatTest(params) {
  return http.post(`${baseUrl}/cat/startCatTest`, params)
}
// 自适应测试-提交、跳过题目
function catPush(params) {
  return http.post(`${baseUrl}/cat/catPush`, params)
}
// 自适应测试-CAT测试超时
function catTestTimeout(params) {
  return http.post(`${baseUrl}/cat/catTestTimeout`, params)
}
// 自适应测试-获取CAT报告
function catTestReport(params) {
  return http.post(`${baseUrl}/cat/catTestReport`, params)
}
// 自适应测试-专题详细信息
function getTopicInfo(params) {
  return http.post(`${baseUrl}/cat/getTopicInfo`, params)
}

// 训练中心-开始考试
function startTrainExamination(params) {
  return http.post(`${baseUrl}/trainCenter/startExamination`, params)
}
// 训练中心-提交答案
function submitTrainAnswer(params) {
  return http.post(`${baseUrl}/trainCenter/submitAnswer`, params)
}
// 训练中心-结束考试
function finishTrainExamination(params) {
  return http.post(`${baseUrl}/trainCenter/finishExamination`, params)
}
// 训练中心-获取报告
function getTrainExaminationReport(params) {
  return http.post(`${baseUrl}/trainCenter/getExaminationReport`, params)
}
// 训练中心-获取已完成试题
function getFinishQuestion(params) {
  return http.post(`${baseUrl}/trainCenter/getFinishQuestion`, params)
}


// 错题本-开始考试
function startErrorExamination(params) {
  return http.post(`${baseUrl}/errorQuestion/startExamination`, params)
}
// 错题本-提交答案
function submitErrorAnswer(params) {
  return http.post(`${baseUrl}/errorQuestion/submitAnswer`, params)
}
// 错题本-结束考试
function finishErrorExamination(params) {
  return http.post(`${baseUrl}/errorQuestion/finishExamination`, params)
}
// 错题本-获取报告
function getErrorExaminationReport(params) {
  return http.post(`${baseUrl}/errorQuestion/getExaminationReport`, params)
}

// 名师训练题集-列表
function getFamousTeacherTrainList(params) {
  return http.post(`${baseUrl}/trainCenter/getFamousTeacherTrainList`, params)
}
// 名师训练题集-详情
function getTrainSetInfo(params) {
  return http.post(`${baseUrl}/trainCenter/getTrainSetInfo`, params)
}
// 名师训练题集-开始考试
function startFamousTeacherTrain(params) {
  return http.post(`${baseUrl}/trainCenter/startFamousTeacherTrain`, params)
}
// 名师训练题集-提交答案
function submitFamousTeacherQuestion(params) {
  return http.post(`${baseUrl}/trainCenter/submitFamousTeacherQuestion`, params)
}
// 名师训练题集-获取报告
function getFamousTeacherTrainReport(params) {
  return http.post(`${baseUrl}/trainCenter/getFamousTeacherTrainReport`, params)
}
// 名师训练题集-获取已完成
function getCompletedQuestions(params) {
  return http.post(`${baseUrl}/trainCenter/getCompletedQuestions`, params)
}
// 名师训练题集-获取已完成
function famousTeacherWeekStatistic(params) {
  return http.post(`${baseUrl}/trainCenter/famousTeacherWeekStatistic`, params)
}


// 题目-获取题目信息
function getQuestionInfo(params) {
  return http.post(`${baseUrl}/question/getQuestionInfo`, params)
}
// 题目-获取错因
function getQuestionErrorInfo(params) {
  return http.post(`${baseUrl}/errorQuestion/getQuestionErrorInfo`, params)
}
// 题目-修改错因
function updateQuestionErrorInfo(params) {
  return http.post(`${baseUrl}/errorQuestion/updateQuestionErrorInfo`, params)
}
export default {
  login,
  register,
  getIndustryTree,
  getTree,
  getUserInfo,
  getTopicList,
  getTopicTitleList,
  getTopicArchive,
  getErrorQuestionList,
  getTrainingCenterList,
  getTreeMap,
  getUserCatRecord,
  getReport,

  startCatTest,
  catPush,
  catTestTimeout,
  catTestReport,
  getTopicInfo,

  startTrainExamination,
  submitTrainAnswer,
  finishTrainExamination,
  getTrainExaminationReport,
  getFinishQuestion,

  startErrorExamination,
  submitErrorAnswer,
  finishErrorExamination,
  getErrorExaminationReport,

  getFamousTeacherTrainList,
  getTrainSetInfo,
  startFamousTeacherTrain,
  submitFamousTeacherQuestion,
  getFamousTeacherTrainReport,
  getCompletedQuestions,
  famousTeacherWeekStatistic,

  getQuestionInfo,
  getQuestionErrorInfo,
  updateQuestionErrorInfo,
}

