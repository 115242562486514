<!--
 * @Author: wyq
 * @Date: 2021-06-08 14:47:45
 * @LastEditTime: 2021-10-27 14:33:40
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\public\publicHeader.vue
-->
<template>
  <div class="header">
    <v-btn v-if="leftArrow" class="rounded-sm" fab color="#fff" @click="goback">
      <v-icon dark>mdi-chevron-left</v-icon>
    </v-btn>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'publicHeader',
  props: {
    leftArrow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goback() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  height: 64px;
  padding: 0 32px 0 20px;
  line-height: 64px;
  background-color: #f2f6fc;
  color: #bed1ff;
  font-size: 30px;
  font-family: PingFangSC-Bold;
  .v-btn {
    margin-right: 28px;
    box-shadow: 0px 12px 18px 0px rgba(0, 0, 0, 0.08);
  }
}
</style>
