/*
 * @Author: wyq
 * @Date: 2021-09-27 11:00:05
 * @LastEditTime: 2021-12-21 11:11:51
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        topId: 323, //体系ID
        grade: 2, //年级
        subject: 2, //学科
        mini: false,
        catTesting: {
            totalTime: 20,
            questionNum: 12
        },
        msgPool: [],
        tagList: [{
            label: '知识点未掌握',
            value: 1,
        }, {
            label: '解题方法未掌握',
            value: 2,
        }, {
            label: '计算错误',
            value: 3,
        }, {
            label: '粗心错误',
            value: 4,
        }],
        // topicList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17] //章节index
    },
    getters: {
        getMini(state) {
            return state.mini
        }
    },
    mutations: {
        changeMini(state, mini) {
            state.mini = mini;
        },
        message(state, step) {
            state.msgPool.push(step)
            //将消息添加到消息列表
            setTimeout(() => {
                state.msgPool.shift()
            }, step.time);
            //清除消息
        }
    }
})