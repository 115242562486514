<!--
 * @Author: wyq
 * @Date: 2021-06-08 14:48:00
 * @LastEditTime: 2021-10-19 14:28:47
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\public\publicAside.vue
-->
<template>
  <v-card class="aside rounded-lg">
    <v-navigation-drawer
      :mini-variant.sync="$store.state.mini"
      color="primary"
      dark
      mini-variant-width="92"
      permanent
      touchless
    >
      <v-list-item>
        <v-list-item-avatar color="#f2f6fc">
          <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
          {{userSurname}}
        </v-list-item-avatar>
        <v-list-item-title></v-list-item-title>
        <v-btn icon @click.stop="changeMini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>{{userName}} {{userClass}}</v-list-item-title>
      </v-list-item>

      <v-divider></v-divider>

      <ul class="menu">
        <li
          v-for="item in items"
          :key="item.title"
          :class="{active:item.value==currentRoute}"
          class="menu-item"
          @click.stop="changeRoute(item.value)"
        >
          <v-icon>{{ item.icon }}</v-icon>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </li>
      </ul>
      <template v-slot:append>
        <v-btn icon class="setting" @click="changeRoute('Setting')">
          <v-icon>icon-settings</v-icon>
        </v-btn>
      </template>
    </v-navigation-drawer>
  </v-card>
</template>
<script>
export default {
  name: 'publicAside',
  data() {
    return {
      items: [
        {
          id: 1,
          title: '自适应测试',
          icon: 'icon-zsy',
          value: 'adaptiveTesting',
        },
        {
          id: 2,
          title: '训练中心',
          icon: 'icon-xlzx',
          value: 'trainingCenter',
        },
        {
          id: 5,
          title: '名师工作室',
          icon: 'icon-msgzs',
          value: 'teacherStudio',
        },
        { id: 3, title: '错题中心', icon: 'icon-ctzx', value: 'wrongQuestion' },
        {
          id: 4,
          title: '学情档案',
          icon: 'icon-xqda',
          value: 'academicArchives',
        },
      ],
      currentId: 1,
    }
  },
  computed: {
    currentRoute() {
      return this.$route.path.split('/')[1]
    },
    userName() {
      let userName = localStorage.getItem('userName')
      return userName ? userName : '张三'
    },
    userClass() {
      let userGrade = localStorage.getItem('userGrade')
      let userClass = localStorage.getItem('userClass')
      return userClass ? userGrade + userClass : '高一（1）班'
    },
    userSurname() {
      return this.userName[0]
    },
  },
  created() {
	let str = localStorage.getItem('userGrade') 
    if(str.indexOf("初")!=-1){
		this.items = [
        {
          id: 1,
          title: '自适应测试',
          icon: 'icon-zsy',
          value: 'adaptiveTesting',
        },
        {
          id: 2,
          title: '训练中心',
          icon: 'icon-xlzx',
          value: 'trainingCenter',
        },
        { id: 3, title: '错题中心', icon: 'icon-ctzx', value: 'wrongQuestion' },
        {
          id: 4,
          title: '学情档案',
          icon: 'icon-xqda',
          value: 'academicArchives',
        }
      ]
	}
  },
  methods: {
    changeMini() {
      this.$store.commit('changeMini', true)
    },
    changeRoute(val) {
      if (val && val != this.currentRoute) {
        this.$router.push({
          path: `/${val}`,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.aside {
  height: calc(100vh - 24px);
  margin: 12px;
  .menu {
    color: #fff;
    padding: 0;
    margin-top: 26px;
    &-item {
      display: flex;
      height: 60px;
      padding: 0 18px;
      border-radius: 16px;
      font-family: PingFangSC-Medium;
      ::v-deep .v-list-item__title {
        font-size: 18px;
      }
      &.active {
        background-color: #fff;
        color: #333;
        .v-icon {
          color: #0148ff;
        }
      }
      .v-icon {
        color: #fff;
        font-size: 28px;
        margin-right: 18px;
      }
    }
  }
  .setting {
    margin: 20px 18px;
  }
  ::v-deep .v-navigation-drawer {
    padding: 14px;
  }
  .v-avatar {
    color: #333;
  }
}
</style>

