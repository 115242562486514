<!--
 * @Author: wyq
 * @Date: 2021-09-27 15:59:54
 * @LastEditTime: 2021-10-09 09:27:37
 * @LastEditors: wyq
 * @Description: 
 * @FilePath: \cat_student\src\components\mathKeyboard\render.vue
-->
<template>
  <div class="math-render">
    <div :id="mathview" class="mathview"></div>
    <span v-if="imgshow && !value" class="placeholder">请输入答案</span>
  </div>
</template>

<script>
import 'mathquill/build/mathquill'

export default {
  name: 'math-render',
  data() {
    return {
      mathview:
        'mathview' +
        parseInt(Math.random() * (999999 - 100000 + 1) + 100000, 10),
      viewField: null,
    }
  },
  props: {
    value: String,
    imgshow: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    var that = this
    var mathview = document.getElementById(this.mathview)

    var MQ = MathQuill.getInterface(2)

    var viewField = (that.viewField = MQ.MathField(mathview))

    viewField.latex(that.value)
  },
  watch: {
    value: function (val) {
      this.viewField.latex(val)
    },
  },
}
</script>

<style scoped>
.mathview {
  display: block;
  border: none;
  border-bottom: 1px solid #e0edff;
}

.math-render {
  position: relative;
  width: 100%;
}

.math-render .placeholder {
  position: absolute;
  left: 10px;
  top: 0;
  height: 24px;
  color: #999;
}
</style>

<style>
.mathview textarea {
  display: none !important;
}

.mathview .mq-cursor {
  display: none !important;
}
</style>